
.carousel-container {
  position: relative;
  width: 95%;
  margin: auto;
  height: auto;
  border: 1px solid red;
}
.carousel {
  /* overflow: hidden; */
  width: 100%;
  margin: auto;

}
/* .slick-track {display: flex;} */

.inner {
  white-space: nowrap;
  margin: auto;
  transition: transform 0.3s;
}

.carousel-item {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  align-items: center;
  overflow: hidden;
}
.largeSliderContainer .slick-list{ height: auto}
.carousel-btn {
  height: 100%;
  font-size: 30px;
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  z-index: 1000;
}
.carousel-btn:active,
.carousel-btn:focus {
  outline: none;
}

#carousel-prev {
  left: 0px;
  top: 0px;
}
#carousel-next {
  right: 0px;
  top: 0px;
}

.slick-slide {
  box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list, .slick-slider {
  position: relative;
  display: block;
}

/* .slick-slider .slick-list, .slick-slider .slick-track {
  -ms-transform: translateZ(0);
  transform: translateZ(0);
} */


.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-initialized .slick-slide {
  display: block;
}
/* .slick-arrow {display: none !important;} */


/* .carousel .img-wrapper img {
  display: block;
  height: 400px !important;
  width: 100% !important;
  object-fit: cover;
  object-position: center;
} */

.sliderwrap {overflow: hidden; width: 100%; display: flex;}
.slider-img-large.landscape {max-width: 100% !important;}

@media (min-width:992px) {
  .newrow .pright:not(.videos) .preStyle {transform: translateX(-50px);}
  .newrow .pright:not(.videos) .nextStyle {transform: translateX(50px);}
}
@media (max-width:1200px) {
  .sliderwrap {height: 400px;}
}
@media (max-width: 768px) {
  .sliderwrap {height: 250px;}
}

.newrow .sa-slider .slick-prev, .newrow .sa-slider .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    color: transparent !important;
    outline: none;
}
.newrow .slick-prev:before, .newrow .slick-next:before {color: #e2e8f0 !important;}
