@import url(https://fonts.googleapis.com/css?family=Forum&display=swap);
.addMain{width:100%;height:80vh;margin-top:100px;display:flex;justify-content:center}.addMain>div:nth-child(1){width:35%}.addMain>div:nth-child(1)>h1:nth-child(1){text-align:center;color:gray}.addMain>div:nth-child(1) .input__box{display:flex;flex-direction:column;margin-top:30px}.addMain>div:nth-child(1) .input__box>label{font-size:17px;color:#000}.addMain>div:nth-child(1) .input__box>input{margin-top:10px;border:1px solid gray;height:45px;border-radius:10px;padding-left:20px}.addMain>div:nth-child(1) .offcieSubmit{display:flex;justify-content:center;margin-top:70px}.addMain>div:nth-child(1) .offcieSubmit>button{border:1px solid gray;padding:10px 50px;border-radius:10px;font-size:18px}@media(min-width: 769px){.popup .oh__yes{width:100%;height:100%}.popup .slick-slide{width:auto !important}.popup .slick-list{height:400px !important}.popup .oh{display:flex;justify-content:center;z-index:1000001 !important;margin:auto;padding-right:0px;width:100%;height:auto;position:relative}}@media(max-width: 768px){.popup .oh__yes{width:100%;height:40%}.popup .popup_wrapper{height:auto;width:auto;align-items:center;justify-content:center}.popup .popoImg{height:auto;width:auto}}
@font-face {
    font-family: 'helvetica-neue-thin';
    src: url(/static/media/HelveticaNeueThin.aee0ba26.woff2) format('woff2'),
        url(/static/media/HelveticaNeueThin.979ac420.woff) format('woff'),
        url(/static/media/HelveticaNeueThin.c4606aee.ttf) format('ttf');
}

.helvetica-neue-thin {
    font-family: helvetica-neue-thin;
}

@font-face {
    font-family: 'helvetica-neue-regular';
    src: url(/static/media/HelveticaNeueRegular.63b29638.woff2) format('woff2'),
        url(/static/media/HelveticaNeueRegular.5677085e.woff) format('woff'),
        url(/static/media/HelveticaNeue.50a6cd50.ttf) format('ttf');
}

.helvetica-neue-regular{
    font-family: helvetica-neue-regular;
}

@font-face {
    font-family: 'helvetica-neue-medium';
    src: url(/static/media/HelveticaNeueMedium.2226d4bf.woff2) format('woff2'),
        url(/static/media/HelveticaNeueMedium.980f3558.woff) format('woff'),
        url(/static/media/HelveticaNeueMed.49f40b03.ttf) format('ttf');
}

.helvetica-neue-medium{
    font-family: helvetica-neue-medium;
}

@font-face {
    font-family: 'helvetica-neue-heavy';
    src: url(/static/media/HelveticaNeueHeavy.4a78f14e.woff2) format('woff2'),
        url(/static/media/HelveticaNeueHeavy.66cba234.woff) format('woff'),
        url(/static/media/HelveticaNeueHv.1240cfcc.ttf) format('ttf');
}

.helvetica-neue-heavy{
    font-family: helvetica-neue-heavy;
}

@font-face {
    font-family: 'helvetica-neue-light';
    src: url(/static/media/HelveticaNeueLight.70258f85.woff2) format('woff2'),
        url(/static/media/HelveticaNeueLight.c5607da4.woff) format('woff'),
        url(/static/media/HelveticaNeueLt.81d0ae0c.ttf) format('ttf');
}

.helvetica-neue-light{
    font-family: helvetica-neue-light;
}

@font-face {
    font-family: 'helvetica-neue-bold';
    src: url(/static/media/HelveticaNeueBold.3fd49460.woff2) format('woff2'),
        url(/static/media/HelveticaNeueBold.ca9d48aa.woff) format('woff'),
        url(/static/media/HelveticaNeueBd.497b4484.ttf) format('ttf');
}

.helvetica-neue-bold{
    font-family: helvetica-neue-bold;
}

@font-face {
    font-family: 'helvetica-neue-italic';
    src: url(/static/media/HelveticaNeueItalic.535beb89.woff2) format('woff2'),
        url(/static/media/HelveticaNeueItalic.dfd18c48.woff) format('woff'),
        url(/static/media/HelveticaNeueIt.105126d0.ttf) format('ttf');
}

.helvetica-neue-italic{
    font-family: helvetica-neue-italic;
}

@font-face {
    font-family: 'helvetica-neue-black-cond';
    src: url(/static/media/HelveticaNeueBlackCond.789730db.woff2) format('woff2'),
        url(/static/media/HelveticaNeueBlackCond.1f3f6caf.woff) format('woff'),
        url("/static/media/HelveticaNeue BlackCond.86293d93.ttf") format('ttf');
}

.helvetica-neue-black-cond{
    font-family: helvetica-neue-black-cond;
}

/* body {
    font-family: 'Roboto' !important;
} */
@font-face {
    font-family: 'dodit-bold-italic';
    src: url(/static/media/DidotBoldItalic.8b4d57e1.ttf);
}
.dodit-bold-italic{
    font-family: 'dodit-bold-italic';
}
@font-face {
    font-family: 'dodit-bold';
    src: url(/static/media/DidotLTStdBold.63f0d232.otf);
}
.dodit-bold{
    font-family: 'dodit-bold';
}
@font-face {
    font-family: 'dodit-italic';
    src: url(/static/media/DidotLTStdItalic.41bc511d.otf);
}
.dodit-italic{
    font-family: 'dodit-italic';
}
@font-face {
    font-family: 'dodit-medium';
    src: url(/static/media/DidotMedium.1ac913d9.ttf);
}
.dodit-medium{
    font-family: 'dodit-medium';
}
@font-face {
    font-family: 'dodit-regular';
    src: url(/static/media/DidotRegular.89b353f0.ttf);
}
.dodit-regular{
    font-family: 'dodit-regular';
}
.text-caplitalized {
    text-transform: capitalize;
}
/* #slider-container {
  align-items: center;
  display: flex;
  width: 704px;
  max-width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
} */

.img-container {
  display: flex;
  margin: 0;
}
.slider-item {
  /* width: 704px; */
  /* padding: 0 50px; */
  display: flex;
  justify-content: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px; align-items: center; overflow: hidden;
}
.creator.pright .slider-item {justify-content: center; }


.slick-list {
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
  margin-left: -20px;
  /* height: 400px; */
  /* width: calc(100% - 100px) !important; */
}
.slider-img {
  height: 440px;
  width: auto;
  object-fit: cover;
  object-position: top;
}
.slider-img2 {
  height: 440px;
  width: auto;
  object-fit: cover;
  object-position: top;
}
.w-auto {
  height: 380px;
  width: auto;
  object-fit: contain;
  object-position: top;
}
.another{
  margin-left: -190px !important;
}

.w-auto2 {
  height: 380px;
  width: auto;
  object-fit: contain;
  object-position: middle;
}
.videos.pright .slick-list {
  height: auto !important;
}
@media (min-width:768px) {
  .first-items {justify-content: center;}

}
@media (min-width:769px) and (max-width:992px) {
  #slider-container {width: 600px; margin: auto;}
}
@media (max-width:992px) {
  .slider-img { height: 300px !important; width: 240px !important;}
  .single .slider-img {height: 300px !important; width: auto !important;}
  .w-auto { height: 300px !important; }
}
@media (max-width:767px) {
  .slider-img { height: 220px !important; width: 165px !important;}
  .single .slider-img {height: 220px !important; width: auto !important;}
  .single2 .slider-img2 {height: 350px !important; width: auto !important;}
  .w-auto { height: 220px !important; }
  .w-auto2 { object-position: middle !important; }
  .slick-list {
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    margin-left: 0px;
    /* height: 400px; */
    /* width: calc(100% - 100px) !important; */
  }
}

@media (max-width:992px) and (orientation: landscape) {
  .first-items { justify-content: center;}
  .slick-list {width: 100% !important;}
  .newrow .pright:not(.videos) .preStyle {-webkit-transform: translateX(0) !important;transform: translateX(0) !important;}
  .newrow .pright:not(.videos) .nextStyle {-webkit-transform: translateX(0) !important;transform: translateX(0) !important;}
}
@media (max-width:768px)  and (orientation: landscape) {


  .slick-list {height: 267px;}
  .single .w-auto { height: 267px !important;  width: auto; }
  .single .slider-img, .pair .slider-img { height: 267px !important; width: 200px !important;}
}
#limg {
  width: auto;
  margin: 0px 15px;
  height: 395px;
}
.slider-btn {
  height: 100%;
  font-size: 30px;
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  z-index: 1000;
}
.slider-btn:active,
.slider-btn:focus {
  outline: none;
}

.preStyle {
  left: 0px;
  top: 0px;
  color: blue !important;
}
.nextStyle {
  right: 0;
  top: 0px;
}

@media screen and (max-width: 1366px) {

  #limg {
    max-width: 600px;
  }
/*
  .preStyle {
    left: 10px;
  } */
}

@media screen and (max-width: 1280px) {

  #limg {
    max-width: 580px;
  }
}


.slider-img-large {
  width: auto;
  height: 85vh;
  object-fit: contain;
}

#limg-large {
  width: auto;
  height: 85vh;
}


.carousel-item {grid-column-gap: 30px;-webkit-column-gap: 30px;column-gap: 30px;}


@media (max-width:992px) {
.largeSliderContainer .carousel {width: 100%; margin: auto; position: absolute; top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);}
/* .largeSliderContainer .carousel-item {height: 30vh !important; margin-top: 0;} */
/* .largeSliderContainer .carousel-item .slider-img-large {height: 100% !important;} */

}
@media (max-width:992px)  and (orientation: landscape) {
  /* .largeSliderContainer .carousel-item {height: 90vh !important; margin-top: 0;} */
  /* .largeSliderContainer .carousel-item .slider-img-large.pair {max-width: 275px !important; } */
}

@media (max-width:540px)  and (orientation: landscape) {
  /* .largeSliderContainer .carousel-item .slider-img-large.pair {max-width: 200px !important; } */
}
.innerslider{
  height: auto!important;

}

.carousel-container {
  position: relative;
  width: 95%;
  margin: auto;
  height: auto;
  border: 1px solid red;
}
.carousel {
  /* overflow: hidden; */
  width: 100%;
  margin: auto;

}
/* .slick-track {display: flex;} */

.inner {
  white-space: nowrap;
  margin: auto;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.carousel-item {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  align-items: center;
  overflow: hidden;
}
.largeSliderContainer .slick-list{ height: auto}
.carousel-btn {
  height: 100%;
  font-size: 30px;
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  z-index: 1000;
}
.carousel-btn:active,
.carousel-btn:focus {
  outline: none;
}

#carousel-prev {
  left: 0px;
  top: 0px;
}
#carousel-next {
  right: 0px;
  top: 0px;
}

.slick-slide {
  box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list, .slick-slider {
  position: relative;
  display: block;
}

/* .slick-slider .slick-list, .slick-slider .slick-track {
  -ms-transform: translateZ(0);
  transform: translateZ(0);
} */


.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-initialized .slick-slide {
  display: block;
}
/* .slick-arrow {display: none !important;} */


/* .carousel .img-wrapper img {
  display: block;
  height: 400px !important;
  width: 100% !important;
  object-fit: cover;
  object-position: center;
} */

.sliderwrap {overflow: hidden; width: 100%; display: flex;}
.slider-img-large.landscape {max-width: 100% !important;}

@media (min-width:992px) {
  .newrow .pright:not(.videos) .preStyle {-webkit-transform: translateX(-50px);transform: translateX(-50px);}
  .newrow .pright:not(.videos) .nextStyle {-webkit-transform: translateX(50px);transform: translateX(50px);}
}
@media (max-width:1200px) {
  .sliderwrap {height: 400px;}
}
@media (max-width: 768px) {
  .sliderwrap {height: 250px;}
}

.newrow .sa-slider .slick-prev, .newrow .sa-slider .slick-next {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    color: transparent !important;
    outline: none;
}
.newrow .slick-prev:before, .newrow .slick-next:before {color: #e2e8f0 !important;}

.carousel .img-wrapper {
  position: relative;

  width: 100% !important;
}

.carousel .img-wrapper:hover img {
  opacity: 0.2;
}

.carousel .img-wrapper:hover .info {
  display: block;
}

.carousel .img-wrapper img {
  display: block;
  max-width: 100%;
  max-height: 360px;
  width: auto;
  height: 250px;

}
@media (max-width:767px)
{
  .carousel .img-wrapper img {
    display: block;
    width: auto;
    height: 244px;
  }
}

.carousel .info {
  display: none;
  position: absolute;
  top: 0px;
  right: 15px;
  font-family: helvetica-neue-light;
  text-align: right;
  font-style: italic;
  font-size: 10px;
  z-index: 100;
}

.carousel .info .name {
  font-family: helvetica-neue-light;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 0px;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}



.swiper-container {
  width: auto;
  height: auto;
  position: relative;
}
.swiper-container:after {
  content: "";
  display: block;
  width: auto;
  height: auto;
  background: linear-gradient(90deg, black 50%, transparent 50%);
  background-position: -50px;
  background-repeat: repeat-x;
  position: absolute;
  left: 0%;
  top: 40%;
  -webkit-animation: line-slide 100s forwards infinite linear;
          animation: line-slide 100s forwards infinite linear;
  z-index: 1;
}
.swiper-container.swiper--bottom:after {
  animation-direction: reverse;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
  position: relative;
}

.swiper-slide {
  text-align: center;
  font-size: 33px;
  background: #fff;
  width: auto !important;
  position: relative;
  overflow: hidden;
}

@-webkit-keyframes line-slide {
  0% {
    background-position: -5% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes line-slide {
  0% {
    background-position: -5% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.cols {
  -webkit-column-count: 2;
          column-count: 2;
}

.item-container {
  margin-bottom: 40px;
  overflow: hidden;
}

.item-container .content {
  margin-top: 10px;
}

.custoo {
  border: 1px solid black;
  border-radius: 20px;
  padding: 3px 10px;
  cursor: pointer;
}

.custo {
  background-color: black;
  color: white;
  border-radius: 20px;
  padding: 3px 10px;
  cursor: pointer;
}
.pree {
  background-color: black;
  color: white;
  border-radius: 20px;
  padding: 3px 10px;

  cursor: pointer;
}
.popup_wrapper{
  height: 400px !important;
}
.popoImg{
  height: 400px;
  width: auto;
  z-index: 11000;
}

.pre {
  border: 1px solid black;
  border-radius: 20px;
  padding: 3px 10px;
  cursor: pointer;
}
.img-wrapper{
  width:100% !important;

}
.button_plus{
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  position: absolute;
  z-index: 2;
  color: black;
  margin-left: 0px;
  margin-top: 0px;
  padding:5px 10px
}

@media screen and (min-width: 992px) {
  .cols {
    grid-column-gap: 5%;
    -webkit-column-gap: 5%;
            column-gap: 5%;
  }

  .item-container {
    display: flex;
    align-items: flex-end;
    margin-bottom: 80px;
  }
  .workwrap .item-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 80px;
    max-width: 100%;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }
  /* .workwrap .item-container .img-wrapper, .workwrap .item-container .content{
    flex: 0 0 50%;
  } */
  .content {
    font-size: 11px;
    width: 400px;
  }

  .content .name {
    font-size: 14px;
  }

  .right-content .img-wrapper {
    order: 1;
  }

  .right-content .content {
    order: 2;
    margin-left: 0.9375rem;
  }

  .left-content .img-wrapper {
    order: 2;
  }

  .left-content .content {
    order: 1;
    text-align: right;
    margin-right: 0.9375rem;
  }

  .timelineImage {
    width: 800px;
  }
}



.popup {
  /* display: none; */
  position: fixed;
  z-index: 1;
  padding-top: min(7%);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
  display: flex;
  align-items: center;
}

.popup-contentImg {
  position: fixed;

  margin: auto;
  padding-right: 0px;
  width: 50%;
  height: auto;
  position: relative;
}


.popup-content {
  background-color: #ffffff00;
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50vh;
  left: 50vw;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.blocker {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: " ";
  background-color: rgba(0, 0, 0, 0.466);
}

.close {
  position: fixed;
  top: 30px;
  right: 35px;
  z-index: 1;
  color: rgb(0, 0, 0);
}

.close:hover,
.close:focus {
  color: rgb(43, 43, 43);
  text-decoration: none;
  cursor: pointer;
}

.gallery {
  flex-wrap: wrap;
  justify-content: center;
  /* height: 100vw;
    max-height: 800px; */
}

.gallery .img-wrapper img {
  height: 300px;
}

.react-player-timeline video {
  max-height: 95vh;
}

.player-wrapper {
  position: relative;
  padding-top: 66px;
  /* padding-top: 56.25%; */
  /* Player ratio: 100 / (1280 / 720) */
}
.homerow {display: flex; flex-wrap: wrap;}
/* .react-player {
  position: absolute;
  top: 0;
  left: 0;
} */

/* 
    {backgroundColor:'black', display:'flex' ,justifyContent:'center',outline:'none', boxShadow:'0'} */

@media screen and (min-width: 994px) {
  .landscapeImg {
    width: 100%;
    height: auto;
  }

  .portraitImg {
    width: auto;
    height: 360px;
    object-fit: cover;
    object-position: bottom;
  }
}

@media only screen and (max-width: 768px) and (min-width: 400px) {
  .imgtext p {
    font-size: 1rem;
    line-height: 1rem;
  }
  .imgtext a {
    font-size: 1rem;
    line-height: 1rem;
  }

  .landscapeImg {
    width: 100%;
    height: auto;
  }

  .portraitImg {
    width: auto;
    height: 200px;
  }
}

@media only screen and (max-width: 993px) and (min-width: 768px) {
  .landscapeImg {
    width: 100%;
    height: auto;
  }

  .portraitImg {
    width: auto;
    height: 250px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .landscapeImg {
    width: 100%;
    height: auto;
  }

  .portraitImg {
    width: auto;
    height: 270px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .landscapeImg {
    width: 100%;
    height: auto;
  }

  .portraitImg {
    width: auto;
    height: 330px;
  }
}

.homeWidth {
  width: 800px;
}

.height-fixer {
  max-height: 500px;
  height: 500px;
  /* object-fit: fill; */
  overflow: hidden;
}
/* .height-fixer img{
    object-fit: contain;
  } */

.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-top: 10px;
}

/* .custom-file-input {
  visibility: hidden;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select some files";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
} */

.talent-private .pleft {
  font-style: italic;
  /* text-align: right; */
  /* font-family: 'helvetica-neue, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;'; */
}

.talent-private input {
  border-bottom: 1px solid rgba(167, 167, 167, 0.63);
  margin-left: 10px;
}


.talent-private .pleft .input-wrapper {
  margin: 5px 0px;
}

.talent-private input:focus {
  outline: none;
}

option {
  text-transform: uppercase;
}

.talent-private label {
  font-weight: normal;
  text-transform: uppercase;
  margin: 0px;
}

.talent-private .screen {
  border: 1px solid rgba(201, 201, 201, 0.5);
  height: 400px;
  overflow: scroll;
}

.talent-private .addFile {
  border: 1px solid rgba(201, 201, 201, 0.5);
}

.talent-private #category,
.talent-private #gender ,
.talent-private #creator {
  border: 1px solid rgba(167, 167, 167, 0.63);
}

.custom-tab:hover {
  font-weight: bold;
}
.tab-active {
  font-weight: bold;
}
.custom-row {
  display: flex;
  flex-wrap: wrap;
}
.item-img {
  padding: 8px;
  position: relative;
}
.talent-img {
  width: auto;
  height: 115px;
}
.telent-img-delete {
  display: none;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  font-size: 25px;
  color: #fff;
}
.item-img:hover .telent-img-delete {
  display: block;
}

.privay_main{width:98vw;margin-top:100px;padding:10px 50px !important;overflow-x:hidden !important;font-size:18px;display:flex;justify-content:center !important}.privay_main .title{font-weight:bold;text-align:center;font-family:helvetica-neue-italic;padding-bottom:60px}.privay_main .sub-text{font-size:17px;font-weight:500;color:#000;text-align:justify;margin-top:20px;font-family:helvetica-neue-italic}.privay_main .sub-text2{font-size:17px;font-weight:500;color:#000;text-align:justify;margin-top:10px;font-family:helvetica-neue-italic}.privay_main .inner_h3{font-weight:bold;color:#000;padding-top:20px;font-family:helvetica-neue-italic}.privay_main .more__inner{font-weight:bold;color:#000;padding-top:20px;font-family:helvetica-neue-italic}@media(max-width: 786px){.privay_main{width:100vw;margin-top:100px;overflow-x:hidden !important;font-size:18px;display:flex;justify-content:center !important;padding-left:150px}.privay_main .title{font-weight:bold;text-align:center;font-family:helvetica-neue-italic;padding-bottom:60px}.privay_main .sub-text{font-size:17px;font-weight:500;color:#000;text-align:justify;margin-top:20px;font-family:helvetica-neue-italic}.privay_main .sub-text2{font-size:17px;font-weight:500;color:#000;text-align:justify;margin-top:10px;font-family:helvetica-neue-italic}.privay_main .inner_h3{font-weight:bold;color:#000;padding-top:20px;font-family:helvetica-neue-italic}.privay_main .more__inner{font-weight:bold;color:#000;padding-top:20px;font-family:helvetica-neue-italic}}
