.carousel .img-wrapper {
  position: relative;

  width: 100% !important;
}

.carousel .img-wrapper:hover img {
  opacity: 0.2;
}

.carousel .img-wrapper:hover .info {
  display: block;
}

.carousel .img-wrapper img {
  display: block;
  max-width: 100%;
  max-height: 360px;
  width: auto;
  height: 250px;

}
@media (max-width:767px)
{
  .carousel .img-wrapper img {
    display: block;
    width: auto;
    height: 244px;
  }
}

.carousel .info {
  display: none;
  position: absolute;
  top: 0px;
  right: 15px;
  font-family: helvetica-neue-light;
  text-align: right;
  font-style: italic;
  font-size: 10px;
  z-index: 100;
}

.carousel .info .name {
  font-family: helvetica-neue-light;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 0px;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}



.swiper-container {
  width: auto;
  height: auto;
  position: relative;
}
.swiper-container:after {
  content: "";
  display: block;
  width: auto;
  height: auto;
  background: linear-gradient(90deg, black 50%, transparent 50%);
  background-position: -50px;
  background-repeat: repeat-x;
  position: absolute;
  left: 0%;
  top: 40%;
  -webkit-animation: line-slide 100s forwards infinite linear;
          animation: line-slide 100s forwards infinite linear;
  z-index: 1;
}
.swiper-container.swiper--bottom:after {
  animation-direction: reverse;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
  position: relative;
}

.swiper-slide {
  text-align: center;
  font-size: 33px;
  background: #fff;
  width: auto !important;
  position: relative;
  overflow: hidden;
}

@-webkit-keyframes line-slide {
  0% {
    background-position: -5% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes line-slide {
  0% {
    background-position: -5% 0;
  }
  100% {
    background-position: 100% 0;
  }
}