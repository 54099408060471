.player-wrapper {
  position: relative;
  padding-top: 66px;
  /* padding-top: 56.25%; */
  /* Player ratio: 100 / (1280 / 720) */
}
.homerow {display: flex; flex-wrap: wrap;}
/* .react-player {
  position: absolute;
  top: 0;
  left: 0;
} */

/* 
    {backgroundColor:'black', display:'flex' ,justifyContent:'center',outline:'none', boxShadow:'0'} */

@media screen and (min-width: 994px) {
  .landscapeImg {
    width: 100%;
    height: auto;
  }

  .portraitImg {
    width: auto;
    height: 360px;
    object-fit: cover;
    object-position: bottom;
  }
}

@media only screen and (max-width: 768px) and (min-width: 400px) {
  .imgtext p {
    font-size: 1rem;
    line-height: 1rem;
  }
  .imgtext a {
    font-size: 1rem;
    line-height: 1rem;
  }

  .landscapeImg {
    width: 100%;
    height: auto;
  }

  .portraitImg {
    width: auto;
    height: 200px;
  }
}

@media only screen and (max-width: 993px) and (min-width: 768px) {
  .landscapeImg {
    width: 100%;
    height: auto;
  }

  .portraitImg {
    width: auto;
    height: 250px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .landscapeImg {
    width: 100%;
    height: auto;
  }

  .portraitImg {
    width: auto;
    height: 270px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .landscapeImg {
    width: 100%;
    height: auto;
  }

  .portraitImg {
    width: auto;
    height: 330px;
  }
}

.homeWidth {
  width: 800px;
}

.height-fixer {
  max-height: 500px;
  height: 500px;
  /* object-fit: fill; */
  overflow: hidden;
}
/* .height-fixer img{
    object-fit: contain;
  } */

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-top: 10px;
}

/* .custom-file-input {
  visibility: hidden;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select some files";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
} */
