.talent-private .pleft {
  font-style: italic;
  /* text-align: right; */
  /* font-family: 'helvetica-neue, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;'; */
}

.talent-private input {
  border-bottom: 1px solid rgba(167, 167, 167, 0.63);
  margin-left: 10px;
}


.talent-private .pleft .input-wrapper {
  margin: 5px 0px;
}

.talent-private input:focus {
  outline: none;
}

option {
  text-transform: uppercase;
}

.talent-private label {
  font-weight: normal;
  text-transform: uppercase;
  margin: 0px;
}

.talent-private .screen {
  border: 1px solid rgba(201, 201, 201, 0.5);
  height: 400px;
  overflow: scroll;
}

.talent-private .addFile {
  border: 1px solid rgba(201, 201, 201, 0.5);
}

.talent-private #category,
.talent-private #gender ,
.talent-private #creator {
  border: 1px solid rgba(167, 167, 167, 0.63);
}

.custom-tab:hover {
  font-weight: bold;
}
.tab-active {
  font-weight: bold;
}
.custom-row {
  display: flex;
  flex-wrap: wrap;
}
.item-img {
  padding: 8px;
  position: relative;
}
.talent-img {
  width: auto;
  height: 115px;
}
.telent-img-delete {
  display: none;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  font-size: 25px;
  color: #fff;
}
.item-img:hover .telent-img-delete {
  display: block;
}
