.addMain{
     width: 100%;
     height: 80vh;
     margin-top: 100px;
     display: flex;
     justify-content: center;
     >div:nth-child(1)
     {

         width: 35%;
         >h1:nth-child(1)
         {
          text-align: center;
          color: gray;
         }
         .input__box{
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          >label{
            font-size: 17px;
            color: black;
          }
          >input{
               margin-top: 10px;
               border: 1px solid gray;
               height: 45px;
               border-radius: 10px;
               padding-left: 20px;
          }
         }
         .offcieSubmit{
          display: flex;
          justify-content: center;
          margin-top: 70px;
          >button{
                 border: 1px solid gray;
                 padding: 10px 50px;
                 border-radius: 10px;
                 font-size: 18px;
          }
         }
     }
}

@media(min-width:769px)
{
.popup{
     .oh__yes{
          width: 100%;
          height: 100%;
     }
     .slick-slide {
          width: auto !important;
        }
        .slick-list{
          height: 400px !important;
        }
        .oh{
          display: flex;
          justify-content: center;
          z-index: 1000001 !important;
          margin: auto;
          padding-right: 0px;
          width: 100%;
          height: auto;
          position: relative;
       }
}


}
@media(max-width:768px)
{
  .popup{
     .oh__yes{
          width: 100%;
          height: 40%;
     }
     .popup_wrapper{
          height: auto;
          width: auto;
          align-items: center;
          justify-content: center;
        }
        .popoImg{
          height: auto;
          width: auto;

        }
  }

}