.privay_main{
    width: 98vw;
    margin-top: 100px;
    padding: 10px 50px !important;
    overflow-x: hidden !important;
    font-size: 18px;
    display: flex;
    justify-content: center !important;
    .title
    {
        font-weight: bold;
        text-align: center;
        font-family: helvetica-neue-italic;
        padding-bottom: 60px;
    }
    .sub-text{
        font-size: 17px;
        font-weight: 500;
        color: black;
        text-align: justify;
        margin-top: 20px;
        font-family: helvetica-neue-italic;
    }
    .sub-text2{
        font-size: 17px;
        font-weight: 500;
        color: black;
        text-align: justify;
        margin-top: 10px;
        font-family: helvetica-neue-italic;
    }
    .inner_h3{
        font-weight: bold;
        color: black;
        padding-top: 20px;
        font-family: helvetica-neue-italic;
    }
    .more__inner{
        font-weight: bold;
        color: black;
        padding-top: 20px;
        font-family: helvetica-neue-italic;
    }
}

@media(max-width:786px)
{
.privay_main {
        width: 100vw;
        margin-top: 100px;
        overflow-x: hidden !important;
        font-size: 18px;
        display: flex;
        justify-content: center !important;
        padding-left: 150px;
        .title {
            font-weight: bold;
            text-align: center;
            font-family: helvetica-neue-italic;
            padding-bottom: 60px;
        }

        .sub-text {
            font-size: 17px;
            font-weight: 500;
            color: black;
            text-align: justify;
            margin-top: 20px;
            font-family: helvetica-neue-italic;
        }

        .sub-text2 {
            font-size: 17px;
            font-weight: 500;
            color: black;
            text-align: justify;
            margin-top: 10px;
            font-family: helvetica-neue-italic;
        }

        .inner_h3 {
            font-weight: bold;
            color: black;
            padding-top: 20px;
            font-family: helvetica-neue-italic;
        }

        .more__inner {
            font-weight: bold;
            color: black;
            padding-top: 20px;
            font-family: helvetica-neue-italic;
        }
    }
}