/* #slider-container {
  align-items: center;
  display: flex;
  width: 704px;
  max-width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
} */

.img-container {
  display: flex;
  margin: 0;
}
.slider-item {
  /* width: 704px; */
  /* padding: 0 50px; */
  display: flex;
  justify-content: center;
  column-gap: 10px; align-items: center; overflow: hidden;
}
.creator.pright .slider-item {justify-content: center; }


.slick-list {
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
  margin-left: -20px;
  /* height: 400px; */
  /* width: calc(100% - 100px) !important; */
}
.slider-img {
  height: 440px;
  width: auto;
  object-fit: cover;
  object-position: top;
}
.slider-img2 {
  height: 440px;
  width: auto;
  object-fit: cover;
  object-position: top;
}
.w-auto {
  height: 380px;
  width: auto;
  object-fit: contain;
  object-position: top;
}
.another{
  margin-left: -190px !important;
}

.w-auto2 {
  height: 380px;
  width: auto;
  object-fit: contain;
  object-position: middle;
}
.videos.pright .slick-list {
  height: auto !important;
}
@media (min-width:768px) {
  .first-items {justify-content: center;}

}
@media (min-width:769px) and (max-width:992px) {
  #slider-container {width: 600px; margin: auto;}
}
@media (max-width:992px) {
  .slider-img { height: 300px !important; width: 240px !important;}
  .single .slider-img {height: 300px !important; width: auto !important;}
  .w-auto { height: 300px !important; }
}
@media (max-width:767px) {
  .slider-img { height: 220px !important; width: 165px !important;}
  .single .slider-img {height: 220px !important; width: auto !important;}
  .single2 .slider-img2 {height: 350px !important; width: auto !important;}
  .w-auto { height: 220px !important; }
  .w-auto2 { object-position: middle !important; }
  .slick-list {
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    margin-left: 0px;
    /* height: 400px; */
    /* width: calc(100% - 100px) !important; */
  }
}

@media (max-width:992px) and (orientation: landscape) {
  .first-items { justify-content: center;}
  .slick-list {width: 100% !important;}
  .newrow .pright:not(.videos) .preStyle {transform: translateX(0) !important;}
  .newrow .pright:not(.videos) .nextStyle {transform: translateX(0) !important;}
}
@media (max-width:768px)  and (orientation: landscape) {


  .slick-list {height: 267px;}
  .single .w-auto { height: 267px !important;  width: auto; }
  .single .slider-img, .pair .slider-img { height: 267px !important; width: 200px !important;}
}
#limg {
  width: auto;
  margin: 0px 15px;
  height: 395px;
}
.slider-btn {
  height: 100%;
  font-size: 30px;
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  z-index: 1000;
}
.slider-btn:active,
.slider-btn:focus {
  outline: none;
}

.preStyle {
  left: 0px;
  top: 0px;
  color: blue !important;
}
.nextStyle {
  right: 0;
  top: 0px;
}

@media screen and (max-width: 1366px) {

  #limg {
    max-width: 600px;
  }
/*
  .preStyle {
    left: 10px;
  } */
}

@media screen and (max-width: 1280px) {

  #limg {
    max-width: 580px;
  }
}


.slider-img-large {
  width: auto;
  height: 85vh;
  object-fit: contain;
}

#limg-large {
  width: auto;
  height: 85vh;
}


.carousel-item {column-gap: 30px;}


@media (max-width:992px) {
.largeSliderContainer .carousel {width: 100%; margin: auto; position: absolute; top: 50%;transform: translateY(-50%);}
/* .largeSliderContainer .carousel-item {height: 30vh !important; margin-top: 0;} */
/* .largeSliderContainer .carousel-item .slider-img-large {height: 100% !important;} */

}
@media (max-width:992px)  and (orientation: landscape) {
  /* .largeSliderContainer .carousel-item {height: 90vh !important; margin-top: 0;} */
  /* .largeSliderContainer .carousel-item .slider-img-large.pair {max-width: 275px !important; } */
}

@media (max-width:540px)  and (orientation: landscape) {
  /* .largeSliderContainer .carousel-item .slider-img-large.pair {max-width: 200px !important; } */
}
.innerslider{
  height: auto!important;

}