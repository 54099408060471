@import url("https://fonts.googleapis.com/css?family=Forum&display=swap");

.cols {
  column-count: 2;
}

.item-container {
  margin-bottom: 40px;
  overflow: hidden;
}

.item-container .content {
  margin-top: 10px;
}

.custoo {
  border: 1px solid black;
  border-radius: 20px;
  padding: 3px 10px;
  cursor: pointer;
}

.custo {
  background-color: black;
  color: white;
  border-radius: 20px;
  padding: 3px 10px;
  cursor: pointer;
}
.pree {
  background-color: black;
  color: white;
  border-radius: 20px;
  padding: 3px 10px;

  cursor: pointer;
}
.popup_wrapper{
  height: 400px !important;
}
.popoImg{
  height: 400px;
  width: auto;
  z-index: 11000;
}

.pre {
  border: 1px solid black;
  border-radius: 20px;
  padding: 3px 10px;
  cursor: pointer;
}
.img-wrapper{
  width:100% !important;

}
.button_plus{
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  backdrop-filter: blur(10px);
  position: absolute;
  z-index: 2;
  color: black;
  margin-left: 0px;
  margin-top: 0px;
  padding:5px 10px
}

@media screen and (min-width: 992px) {
  .cols {
    column-gap: 5%;
  }

  .item-container {
    display: flex;
    align-items: flex-end;
    margin-bottom: 80px;
  }
  .workwrap .item-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 80px;
    max-width: 100%;
    column-gap: 30px;
  }
  /* .workwrap .item-container .img-wrapper, .workwrap .item-container .content{
    flex: 0 0 50%;
  } */
  .content {
    font-size: 11px;
    width: 400px;
  }

  .content .name {
    font-size: 14px;
  }

  .right-content .img-wrapper {
    order: 1;
  }

  .right-content .content {
    order: 2;
    margin-left: 0.9375rem;
  }

  .left-content .img-wrapper {
    order: 2;
  }

  .left-content .content {
    order: 1;
    text-align: right;
    margin-right: 0.9375rem;
  }

  .timelineImage {
    width: 800px;
  }
}



.popup {
  /* display: none; */
  position: fixed;
  z-index: 1;
  padding-top: min(7%);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
  display: flex;
  align-items: center;
}

.popup-contentImg {
  position: fixed;

  margin: auto;
  padding-right: 0px;
  width: 50%;
  height: auto;
  position: relative;
}


.popup-content {
  background-color: #ffffff00;
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.blocker {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: " ";
  background-color: rgba(0, 0, 0, 0.466);
}

.close {
  position: fixed;
  top: 30px;
  right: 35px;
  z-index: 1;
  color: rgb(0, 0, 0);
}

.close:hover,
.close:focus {
  color: rgb(43, 43, 43);
  text-decoration: none;
  cursor: pointer;
}

.gallery {
  flex-wrap: wrap;
  justify-content: center;
  /* height: 100vw;
    max-height: 800px; */
}

.gallery .img-wrapper img {
  height: 300px;
}

.react-player-timeline video {
  max-height: 95vh;
}
